<script lang="ts">
  import type { Teaser } from "$lib/types/shared";
  import Card from "$lib/Card.svelte";


  
  interface Props {
    class?: string;
    teaser: Teaser;
    href: string;
    circleImage?: boolean;
    reverse?: boolean;
    lang?: string;
  }

  let {
    class: classes = "",
    teaser,
    href,
    circleImage = false,
    reverse = false,
    lang = ""
  }: Props = $props();
</script>

<div class="px-edge-x {classes}" lang={lang ? lang : undefined}>
  <Card
    size="full"
    {href}
    pictureObject={teaser.image}
    title={teaser.title}
    {circleImage}
    {reverse}
    --card-title-size="var(--text-h2-lg)"
    --card-title-leading="1.175"
  >
    {#if teaser.description}
      <p class="my-2 text-body">{teaser.description}</p>
    {/if}
  </Card>
</div>
